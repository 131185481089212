import React from "react";
import PropTypes from "prop-types";
import Style from "./AppHeaderMenuBurgerMobile.module.css";
import { Link, matchPath, useLocation } from "react-router-dom";
import { resolvePath, routesWithMobileBurgerMenu, ROUTE_HOME } from "_core/router/routes";
import ElementsJuridiques from "_core/app/elements-juridiques/ElementsJuridiques";
import avatar from "_common/component/icon/avatarMembreDefault.svg";
import clsx from "clsx";
import ReseauxSociaux from "_core/app/reseaux-sociaux/ReseauxSociaux";

const renderIconAsComponent = (menu, isActive) => {
  const Component = menu?.icon.value;
  return isActive ? <Component color="#fff" /> : <Component />;
};

const renderIconAsImage = (menu) => {
  if (menu?.label) {
    return <img src={menu?.icon.value} alt={menu?.label} />;
  }
  // eslint-disable-next-line jsx-a11y/alt-text
  return <img src={menu?.icon.value} />;
};

const renderIcon = (menu, isActive) => {
  return menu?.icon.type === "svg" ? renderIconAsImage(menu) : renderIconAsComponent(menu, isActive);
};

const AppHeaderMenuBurgerMobile = ({ user, isAdherenteActive }) => {
  const location = useLocation();

  return (
    <>
      <div className={clsx(Style.AppMobileMenuBurgerEtFooter, "menu-burger-mobile")}>
        <div className={Style.UserMenuContainer}>
          <img className={Style.PortraitUser} src={user.adherente.photo === "" ? avatar : process.env.REACT_APP_URL_IMG_PETITE + user.adherente.photo} alt="" />
          <span className={Style.UserNameLabel}>{user.adherente.prenom.toLowerCase()}</span>
        </div>
        <div className={Style.AppMobileMenuBurger}>
          <ul>
          {routesWithMobileBurgerMenu.map(({ path, key, menuBurgerMobile }) => {
            path = resolvePath(path, menuBurgerMobile.label === "Mon agenda" ? { pagenum: 1 } : { id: user.adherente.id });
            const isActive = !!matchPath( 
              {
                path: path,
              },
              location.pathname,
            );
            if (menuBurgerMobile.action) {
              const handleLinkClick = () => {
                if (menuBurgerMobile.action.name === 'logout') {
                  window.location.href = ROUTE_HOME;
                }
                menuBurgerMobile.action();
              };
              return (
                <li
                  key={key}
                  data-key="menu-item"
                  
                  onClick={handleLinkClick}
                >
                  <button type="button" className={Style.MenuBurgerItem} onClick={handleLinkClick}>{renderIcon(menuBurgerMobile, isActive)}<span className={Style.MenuBurgerItemLabel}>{menuBurgerMobile?.label}</span></button>
                </li>
              );
            } else {
              return (
                <li key={key} data-key="menu-item">
                  <Link to={isAdherenteActive || (!isAdherenteActive && menuBurgerMobile.label === "Mon compte") ? path : '#'} className={isAdherenteActive || (!isAdherenteActive && menuBurgerMobile.label === "Mon compte") ? Style.MenuBurgerItem : clsx(Style.MenuBurgerItem, Style.MenuBurgerItemInactive)}>
                      {renderIcon(menuBurgerMobile, isActive)}
                      <span className={Style.MenuBurgerItemLabel}>{menuBurgerMobile?.label}</span>
                  </Link>
                </li>
              );
            }
          })}
          </ul>
        </div>
        <div className={Style.FooterInHeader}>
          <ElementsJuridiques />
          <ReseauxSociaux/>
        </div>
      </div>
    </>
  );
};

AppHeaderMenuBurgerMobile.propTypes = {
  user: PropTypes.shape({
    adherente: PropTypes.shape({
      id: PropTypes.number.isRequired,
      prenom: PropTypes.string.isRequired,
      photo: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  isAdherenteActive: PropTypes.bool,
};

export default AppHeaderMenuBurgerMobile;
