import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import { createRoot } from "react-dom/client";
import "_core/css/theme.css";
import App from "_core/app/App";
import "_core/font/Lato-Regular.woff2";
import "_core/font/Lato-Italic.woff2";
import "_core/font/Lato-Bold.woff2";
import "_core/font/Lato-BoldItalic.woff2";
import "_core/font/Lato-Black.woff2";
import "_core/font/Lato-BlackItalic.woff2";
import 'leaflet/dist/leaflet.css';
import * as Sentry from "@sentry/react";
import {
    createRoutesFromChildren,
    matchRoutes,
    Routes,
    useLocation,
    useNavigationType,
} from "react-router-dom";

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['https://monespace.femmesdesterritoires.fr'],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const root = createRoot(document.getElementById('root'));
root.render(
    <App />
);