import React, { useState } from "react";
import Style from "./AppLowerMenuMobile.module.css";
import { NavLink } from "react-router-dom";
import { routesForMobileMenu } from "_core/router/routes";
import ChevronRight from "_common/component/icon/ChevronRight";
import ChevronLeft from "_common/component/icon/ChevronLeft";
import useMedia from "_common/hook/useMedia";
import clsx from "clsx";

const renderIconAsComponent = (menu, isActive) => {
    const Component = menu?.icon.value;
    return isActive ? <Component color="#00AAA5" /> : <Component />;
};
const renderIconAsImage = (menu) => {
    if (menu?.label) {
        return <img src={menu?.icon.value} alt="" />;
    }
    // eslint-disable-next-line jsx-a11y/alt-text
    return <img src={menu?.icon.value} alt="" />;
};
const renderIcon = (menu, isActive) => {
    return menu?.icon.type === "svg" ? renderIconAsImage(menu) : renderIconAsComponent(menu, isActive);
};

const AppLowerMenuMobile = () => {
    const nbShowCount = useMedia(["(max-width: 298px)", "(max-width: 390px)", "(min-width: 426px)"], [4, 5, 99], 99);
    const [partVisible, setPartVisible] = useState(1);

    const showPart = (part) => {
        setPartVisible(part);
    };
    return (
        <nav
            role="navigation"
            aria-label="navigation principale"
            className={clsx(Style.MobileMenuContainer, partVisible === 2 && Style.MobileMenuContainerPart2)}
        >
        {nbShowCount < 99 && partVisible === 2 && (
            <button className={Style.MoreMenuButton} onClick={() => showPart(1)} aria-label="voir plus d'éléments du menu">
                <ChevronLeft size={40} color="#1D1D1F" />
            </button>
        )}
            <ul>
            {partVisible === 1 &&
                routesForMobileMenu
                    .filter((_, i) => i < nbShowCount)
                    .map(({ path, key, menuMobile }) => {
                        return (
                            <li key={key} data-key="menu-item">
                                <NavLink
                                    to={path}
                                    style={({ isActive }) => ({ color: isActive ? "#00AAA5" : "#1D1D1F", fontWeight: isActive ? "bold" : "normal", borderTop: isActive ? "2px solid #00AAA5" : "none" })}
                                >
                                    {({ isActive }) => (
                                    <>
                                        {renderIcon(menuMobile, isActive)}
                                        <span className={Style.MenuItemLabel}>{menuMobile?.label}</span>
                                    </>
                                    )}
                                </NavLink>
                            </li>
                        );
                    })}
            {partVisible === 2 &&
                routesForMobileMenu
                    .filter((_, i) => i >= nbShowCount)
                    .map(({ path, key, menuMobile }) => {
                        return (
                            <li key={key} data-key="menu-item">
                                <NavLink
                                    to={path}
                                    style={({ isActive }) => ({ color: isActive ? "#00AAA5" : "#1D1D1F", fontWeight: isActive ? "bold" : "normal", borderTop: isActive ? "2px solid #00AAA5" : "none" })}
                                >
                                    {({ isActive }) => (
                                    <>
                                        {renderIcon(menuMobile, isActive)}
                                        <span className={Style.MenuItemLabel}>{menuMobile?.label}</span>
                                    </>
                                    )}
                                </NavLink>
                            </li>
                        );
                    })}
            </ul>
            {nbShowCount < 99 && partVisible === 1 && (
                <button className={Style.MoreMenuButton} onClick={() => showPart(2)} aria-label="voir plus d'éléments du menu">
                    <ChevronRight size={40} color="#1D1D1F" />
                </button>
            )}
        </nav>
    );
};

export default AppLowerMenuMobile;
