import React from "react";
import PropTypes from "prop-types";
import Style from "./AppLeftMenu.module.css";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
import { routesWithMenu } from "_core/router/routes";
import { hasRole } from "_core/authentification/service/AuthService";

const renderIconAsComponent = (menu, isActive) => {
    const Component = menu?.icon.value;
    return isActive ? <Component color="#2c827f" /> : <Component />;
};

const renderIconAsImage = (menu) => {
    if (menu?.label) {
        return <img src={menu?.icon.value} alt="" aria-hidden="true" />;
    }
    // eslint-disable-next-line jsx-a11y/alt-text
    return <img src={menu?.icon.value} alt="" aria-hidden="true" />;
};

const renderIcon = (menu, isActive) => {
    return menu?.icon.type === "svg" ? renderIconAsImage(menu) : renderIconAsComponent(menu, isActive);
};

const AppLeftMenu = ({ user }) => {
    return (
        <nav id="navigation-principale" aria-label="navigation principale" className={Style.Navigation}>
            <ul>
                {routesWithMenu.map(({ path, key, menu, roles }) => {
                    const hasPermission = hasRole(roles, user);
                    if (!hasPermission) {
                        return null;
                    }

                    return (
                        <li key={key} data-key="menu-item">
                        <NavLink
                            to={path}
                            className={({ isActive }) => clsx(Style.MenuItem, isActive ? Style.MenuItemSelected : null)}
                        >
                            {({ isActive }) => (
                            <>
                                {renderIcon(menu, isActive)}
                                <span className={Style.MenuItemLabel}>{menu?.label}</span>
                            </>
                            )}
                        </NavLink>
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
};

AppLeftMenu.propTypes = {
  user: PropTypes.object,
}

export default AppLeftMenu;
